<!--
 * @Descripttion:专家抽取 -- 第一步
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 10:14:02
-->
<template>
	<div>
		<form id="add-form" class="whiteBg">
			<div>
				<i class="icon-code wh05 mar-r2" style="background-size:80% auto"></i>
				<p>项目编号</p>
				<input type="text" placeholder="请输入" v-model="project.projectNum">
				<button class="fc" @click.prevent="getProjectInfo">复制项目信息</button>
			</div>
			<div>
				<i class="icon-project wh05 mar-r2"></i>
				<p>项目名称</p>
				<input type="text" placeholder="请输入" v-model="project.projectName">
			</div>
			<div>
				<i class="icon-unit wh05 mar-r2"></i>
				<p>采购单位</p>
				<input type="text" placeholder="请输入" v-model="project.purchaseOrg">
			</div>

			<div>
				<i class="icon-time wh05 mar-r2"></i>
				<p>评审时间</p>
				<input type="text" placeholder="请输入" readonly @focus="onShowTimePicked" v-model="project.reviewDatetime">
			</div>
			<div>
				<i class="icon-addr wh05 mar-r2"></i>
				<p>评审地点</p>
				<input type="text" placeholder="请输入" v-model="project.reviewSite">
			</div>
			<div class="w-line" style="padding:0"></div>
			<div>
				<i class="icon-package wh05 mar-r2"></i>
				<p>包组编号</p>
				<input type="text" placeholder="请输入" v-model="project.packageNum">
			</div>
			<div>
				<i class="icon-name wh05 mar-r2"></i>
				<p>专家人数</p>
				<input type="number" placeholder="请输入" v-model="project.expertNum">
			</div>
			<div class="mar-t2 flex-between">
				<div>
					<i class="icon-profession wh05 mar-r2"></i>
					<p class="inline fw">评审专业</p>
				</div>
				<div>
					<!--
                    <button class="delBtn f35" v-show="!onDel" @click.prevent="onDel= true">删除</button>
                    -->
					<button class="addBtn f35" @click.prevent="onSelectMajors">添加</button>
					<!--
                    <button class="delBtn f35" v-show="onDel" @click.prevent="onDel= false">完成删除</button>
                    -->
				</div>
			</div>
			<div v-show="orgExtrMajors.length > 0">
				<p class="f35 red" style="width: 100%;text-align:right">不指定人数时，表示该专业不限制抽取人数</p>
			</div>
			<div>

				<ul class="w-100">
					<li class="flex-between" v-for="(item,index) in orgExtrMajors" style="margin-top: 0.2rem">
						<span class="center van-ellipsis greenLi fw">
							<i class="del" @click="removeChoosedMajor(item,index)"></i>
							{{ item.parentIdDictText +"-" +showMajorAlias(item) }}
						</span>
						<input type="number" @input="onInput($event.target,item)" placeholder="指定人数" class="step" v-model="item.num"
						 @focus="toView($event)">
					</li>
				</ul>
			</div>

			<div class="w-line" style="padding:0;height:2.5rem"></div>
			<p class="whiteBg pad-row pad-col3 fixed-bottom center">
				<button class="btn fw" style="font-size:0.45rem" @click.prevent="save">保存项目</button>
				<button class="btn fw mar-l" style="font-size:0.45rem" @click.prevent="next">抽取专家</button>
			</p>
		</form>
		<!-- 时间拾取器 -->
		<van-popup v-model="showTime" position="bottom">
			<van-datetime-picker :value='defaultDtVal' type='datetime' title='选择评审日期' @cancel="showTime=false" @confirm="onConfirmTime">

			</van-datetime-picker>
		</van-popup>
	</div>
</template>
<script>
	import {
		DatetimePicker,
		Stepper,
		Switch,
		Popup,
		Dialog
	} from 'vant'
	export default {
		data() {
			return {
				showTime: false,
				minDate: new Date(),
				defaultDtVal: null,
				project: {
					projectNum: '',
					projectName: '',
					purchaseOrg: '',
					reviewDatetime: '',
					reviewSite: '',
					packageNum: '',
					expertNum: '',
				},
				// 是否指定专业抽取人数
				// assign : false,
				// majorsNum:[],		//评审专业抽取人数
				orgExtrMajors: [], //选中的专业数组
				// 删除 评审专业
				onDel: false,
				allAsign: true,
				orgExternal: {
					id: '',
					serviceStatus: '',
					surplusDays: 0,
				},
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},
		components: {
			[DatetimePicker.name]: DatetimePicker,
			[Stepper.name]: Stepper,
			[Switch.name]: Switch,
			[Popup.name]: Popup,
			[Dialog.name]: Dialog,
		},
		/** 去除指定人数开关，屏蔽 此开关的监听
	watch:{
		assign(newV){
			if(newV){
				for(let i=0;i<this.orgExtrMajors.length;i++){
					let e1 = {}
					e1.majorName = this.orgExtrMajors[i].name
					e1.majorId = this.orgExtrMajors[i].id
					e1.parentId = this.orgExtrMajors[i].parentId
					e1.firstClassId = this.orgExtrMajors[i].firstClassId
					e1.parentIdDictText = this.orgExtrMajors[i].parentIdDictText
					e1.num = 1
					//
					this.majorsNum.push(e1)
				}
			}else{
				this.majorsNum = []
			}
		}
	},
    */
		beforeRouteEnter(to, from, next) {
			next(vm => {
				//选择专业
				if (from.name == "selectmajors") {
					// alert(JSON.stringify(vm.orgExtrMajors))
				} else {
					//初始化
					vm.init()
				}
			})
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$ls.get("edCurrentOrgExternal")) {
				this.orgExternal = this.$ls.get("edCurrentOrgExternal");
			}
			this.defaultDtVal = new Date();

			//添加专业页面增加的评审专业
			if (this.$ls.get("orgExtrMajors")) {
				this.orgExtrMajors = this.$ls.get("orgExtrMajors");
			}
			//由项目记录进入
			if (this.$route.query.fromPage && "unextract" == this.$route.query.fromPage) {
				//置空评审专业选择
				this.orgExtrMajors = []
				//置空评审专业指定人数
				// this.majorsNum = []
				//
				let projectId = this.$route.query.projectId
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				//先保存项目
				let __this = this
				this.$ajax({
					url: "/gateway/ed/extract/getTemporarySaveProject",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
						projectId: projectId,
					},
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						//项目
						__this.project = res.data.project
						//评审专业
						__this.orgExtrMajors = res.data.orgExtrMajors
					}
				}).catch((err) => {
					alert(JSON.stringify(err))
				})
			}

		},
		activated() {
			this.$emit('onActive', 1)
			//添加专业页面增加的评审专业
			if (this.$ls.get("orgExtrMajors")) {
				this.orgExtrMajors = this.$ls.get("orgExtrMajors");
			}
			//由项目记录进入
			if (this.$route.query.fromPage && "unextract" == this.$route.query.fromPage) {
				//置空评审专业选择
				this.orgExtrMajors = []
				//置空评审专业指定人数
				this.majorsNum = []
				//
				let projectId = this.$route.query.projectId
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				//先保存项目
				let __this = this
				this.$ajax({
					url: "/gateway/ed/extract/getTemporarySaveProject",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
						projectId: projectId,
					},
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						//项目
						__this.project = res.data.project
						//
						__this.orgExtrMajors = res.data.orgExtrMajors
					}
				}).catch((err) => {
					alert(JSON.stringify(err))
				})
			}
		},
		methods: {
			//初始化数据
			init() {
				//清理专业缓存
				this.$ls.remove("orgExtrMajors");
				this.orgExtrMajors = []
				//
				this.showTime = false
				this.defaultDtVal = null
				//
				this.project.id = ''
				this.project.projectNum = ''
				this.project.projectName = ''
				this.project.purchaseOrg = ''
				this.project.reviewDatetime = ''
				this.project.reviewSite = ''
				this.project.packageNum = ''
				this.project.expertNum = ''
				//
				// this.assign = false

				// this.majorsNum = []
			},
			//打开时间选择器
			onShowTimePicked() {
				document.activeElement.blur();
				this.showTime = true;
				if (this.project.reviewDatetime == '') {
					this.defaultDtVal = new Date();
				} else {
					this.defaultDtVal = new Date(this.project.reviewDatetime.replace(/-/g, '/'));
				}
			},
			//选择时间
			onConfirmTime: function(e) {
				let y = e.getFullYear()
				let m = '0' + (e.getMonth() + 1)
				let d = '0' + e.getDate()
				let h = '0' + e.getHours()
				let mi = '0' + e.getMinutes()
				let s = '0' + e.getSeconds()
				this.project.reviewDatetime = y + "-" + m.slice(-2) + "-" + d.slice(-2) + " " + h.slice(-2) + ":" + mi.slice(-2) +
					":" + s.slice(-2)
				//关闭
				this.showTime = false
			},
			//暂存项目
			save: function() {
				//校验是否开通服务
				if (this.orgExternal.serviceStatus == null || this.orgExternal.serviceStatus == '00' || this.orgExternal.serviceStatus ==
					'02') {
					Dialog.confirm({
						title: '提示',
						message: '本功能需要开通专家记服务才能使用',
						confirmButtonText: '去开通服务',
						cancelButtonText: '取消',
					}).then(() => { //确认
						this.$router.push({
							path: '/renew'
						});
					}).catch(() => {
						console.log('取消')
					})
					return;
				}
				if (!this.projectCheck())
					return
				//
				let org = this.$ls.get("edCurrentOrg")
				this.project.orgId = org.id
				let user = this.$ls.get("edCurrentUser")
				if (this.project.id != '') {
					this.project.updateBy = user.id
				} else {
					this.project.createBy = user.id
				}
				//操作员是否看见
				/** 若用户是管理员则操作员不可见，若用户是操作员则操作员可见 */
				let adminFlag = this.$ls.get('edCurrentUser').adminFlag
				this.project.showFlag = adminFlag == '1' ? '0' : '1'
				//项目状态
				this.project.projectStatus = '00'
				//项目
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				//项目评审专业映射关系
				let maps = []
				for (let i = 0; i < this.orgExtrMajors.length; i++) {
					//映射关系
					let e1 = {}
					e1.majorId = this.orgExtrMajors[i].id
					e1.parentMajorId = this.orgExtrMajors[i].parentId
					e1.firstClassId = this.orgExtrMajors[i].firstClassId
					//人数
					let num1 = "0"
					if (this.orgExtrMajors[i].num) {
						num1 = this.orgExtrMajors[i].num
					}
					if (num1 == "") {
						num1 = "0"
					}
					e1.num = num1 + ""
					maps.push(e1)
				}
				let postData = {}
				postData.project = this.project
				postData.round = 1
				postData.majorMap = maps
				//保存
				let __this = this
				this.$ajax({
					url: "/gateway/ed/project/tbEdProject/temporarySaveProject",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
						// project:JSON.stringify(this.project),
						// criteria:JSON.stringify(criterias),
						// round:1,
						// majorMap:JSON.stringify(maps)
					},
					data: postData,
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						__this.project.id = res.data.projectId
						__this.$toast.success("信息保存成功！")
						return
					}
				}).catch((err) => {
					alert(JSON.stringify(err))
				})
			},
			//跳下一步
			next: function() {
				//校验是否开通服务
				if (this.orgExternal.serviceStatus == null || this.orgExternal.serviceStatus == '00' || this.orgExternal.serviceStatus ==
					'02') {
					Dialog.confirm({
						title: '提示',
						message: '本功能需要开通专家记服务才能使用',
						confirmButtonText: '去开通服务',
						cancelButtonText: '取消',
					}).then(() => { //确认
						this.$router.push({
							path: '/renew'
						});
					}).catch(() => {
						console.log('取消')
					})
					return;
				}
				///////////校验//////////
				if (!this.projectCheck()) {
					return
				}
				//总人数
				let total = this.project.expertNum
				//全部指定的情况下 指定人数和与待抽取人数和必须一致
				let asignNum = 0
				let numReg = /^[1-9][0-9]*$/
				for (let ii = 0; ii < this.orgExtrMajors.length; ii++) {
					let num1 = 0
					if (this.orgExtrMajors[ii].num) {
						if (numReg.test(this.orgExtrMajors[ii].num)) {
							num1 = parseInt(this.orgExtrMajors[ii].num)
						}
					}
					if (num1 <= 0) {
						num1 = 0
						this.allAsign = false
					}
					asignNum = asignNum + num1
				}
				//评审专业全部指定人数
				if (this.allAsign) {
					if (asignNum > total) {
						this.$toast("指定人数总和超过项目专家数，请修改！")
						return
					} else if (asignNum < total) {
						this.$toast("指定人数的总和与项目专家数不符，请修改！")
						return
					}
				} else {
					if (asignNum > total) {
						this.$toast("指定人数总和超过项目专家数，请修改！")
						return
					}
				}
				//写入缓存
				let org = this.$ls.get("edCurrentOrg")
				this.project.orgId = org.id
				let user = this.$ls.get("edCurrentUser")
				if (this.project.id != '') {
					this.project.updateBy = user.id
				} else {
					this.project.createBy = user.id
				}
				//操作员是否看见
				let adminFlag = this.$ls.get('edCurrentUser').adminFlag
				this.project.showFlag = adminFlag == '1' ? '0' : '1'
				//项目状态
				this.project.projectStatus = '00'
				//项目
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				//项目评审专业映射关系
				let maps = []
				for (let i = 0; i < this.orgExtrMajors.length; i++) {
					//映射关系
					let e1 = {}
					e1.majorId = this.orgExtrMajors[i].id
					e1.parentMajorId = this.orgExtrMajors[i].parentId
					e1.firstClassId = this.orgExtrMajors[i].firstClassId
					//指定人数
					let num1 = 0
					if (this.orgExtrMajors[i].num) {
						if (numReg.test(this.orgExtrMajors[i].num)) {
							num1 = parseInt(this.orgExtrMajors[i].num)
						}
					}
					if (num1 <= 0) {
						num1 = 0
					}
					e1.num = num1 + ""
					maps.push(e1)
				}
				///
				this.$ls.set('temporary-project', this.project)
				this.$ls.set('temporary-project-maps', maps)
				//改为不可更改
				for (let ii = 0; ii < this.orgExtrMajors.length; ii++) {
					this.orgExtrMajors[ii].canDel = '0'
				}
				this.$ls.set('temporary-uneditableOrgExtrMajors', this.orgExtrMajors)
				//跳转
				this.$router.push({
					name: 'step3',
					params: {
						firstEnter: '1'
					}
				})
			},
			//选择专业
			onSelectMajors: function() {
				//传回当前选择的专业
				this.$ls.set("orgExtrMajors", this.orgExtrMajors)
				//
				this.assign = false
				//
				this.$router.push({
					name: 'selectmajors',
					query: {
						fromPage: 'step1'
					}
				})
			},
			//校验数据
			projectCheck: function() {
				//项目编号
				let projectNum = this.project.projectNum.replace(/\s+/g, "")
				if (projectNum == '') {
					this.$toast("请填写项目编号！")
					return false
				} else if (projectNum.length > 50) {
					this.$toast("项目编号过长，请修改！")
					return false
				}
				//项目名称
				let projectName = this.project.projectName.replace(/\s+/g, "")
				if (projectName == '') {
					this.$toast("请填写项目名称！")
					return false
				} else if (projectName.length > 200) {
					this.$toast("项目名称过长，请修改！")
					return false
				}
				//采购单位
				let purchaseOrg = this.project.purchaseOrg.replace(/\s+/g, "")
				if (purchaseOrg == '') {
					this.$toast("请填写采购单位！")
					return false
				} else if (purchaseOrg.length > 200) {
					this.$toast("采购单位过长，请修改！")
					return false
				}

				if (this.project.reviewDatetime == '') {
					this.$toast("请填写评审时间！")
					return false
				}
				//评审地点
				let reviewSite = this.project.reviewSite.replace(/\s+/g, "")
				if (reviewSite == '') {
					this.$toast("请填写评审地点！")
					return false
				} else if (reviewSite.length > 200) {
					this.$toast("评审地点过长，请修改！")
					return false
				}
				//包组编号
				let packageNum = this.project.packageNum.replace(/\s+/g, "")
				if (packageNum == '') {
					this.$toast("请填写包组编号！")
					return false
				} else if (packageNum.length > 50) {
					this.$toast("包组编号过长，请修改！")
					return false
				}
				//专家人数
				// let expertNum = this.project.expertNum.replace(/\s+/g, "")
				let reg1 = /^[1-9]\d*$/;
				if (!reg1.test(this.project.expertNum + '')) {
					this.$toast("专家人数必须是大于0的整数！")
					return false
				} else if (this.project.expertNum > 1000) {
					this.$toast("专家人数不能超过1000，请修改！")
					return false
				}
				//是否指定了评审专业
				if (this.orgExtrMajors.length == 0) {
					this.$toast("请选择评审专业！")
					return false
				}
				//评审专业指定人数的判断
				let numReg = /^[1-9][0-9]*$/
				for (let ii = 0; ii < this.orgExtrMajors.length; ii++) {
					if (this.orgExtrMajors[ii].num) {
						if (this.orgExtrMajors[ii].num == "")
							continue
						if (!numReg.test(this.orgExtrMajors[ii].num)) {
							this.$toast("[" + this.orgExtrMajors[ii].parentIdDictText + "-" + this.orgExtrMajors[ii].majorName +
								"]专家人数必须是大于0的整数！")
							return false
						} else if (this.orgExtrMajors[ii].num > 100) {
							this.$toast("[" + this.orgExtrMajors[ii].parentIdDictText + "-" + this.orgExtrMajors[ii].majorName +
								"]专家人数不能超过100，请修改！")
							return false
						}
					}
				}
				//人数判断
				//
				return true
			},
			//移除选择的评审专业
			removeChoosedMajor: function(obj, index) {
				//移除
				this.orgExtrMajors.splice(index, 1)
			},
			//优先显示备注
			showMajorAlias(item) {
				if (item.majorAlias != null && item.majorAlias != '') {
					return item.majorAlias
				} else {
					return item.majorName
				}
			},

			/**
			 * @Description: 根据项目标号查询项目基本信息
			 * @Author: Niklaus
			 * @Date: 2021-02-20 09:08:25
			 */
			getProjectInfo() {
				if (!this.project.projectNum) {
					this.$toast({
						message: '请填写项目编号',
						type: 'fail',
						className: 'div3'
					})
					return
				}
				/** 请求后台查询数据 **/
				this.$ajax({
					method: 'get',
					url: '/gateway/ed/project/tbEdProject/getProjectByNum',
					params: {
						projectNum: this.project.projectNum,
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid")),
						orgId: this.$ls.get("edCurrentOrg").id
					},
					headers: {
						'X-Access-Token': this.$ls.get('token')
					}
				}).then(
					({
						data
					}) => {
						if (data.success) {
							this.project.projectName = data.result.projectName
							this.project.purchaseOrg = data.result.purchaseOrg
							this.project.reviewDatetime = data.result.reviewDatetime
							this.project.reviewSite = data.result.reviewSite
						} else {
							this.$toast.fail(data.message)
						}
					}
				).catch(res => {
					this.$toast.fail('请求错误')
					console.log(res);
				})
			},

			/**
			 * @Description: 将Dom 滚动至 可视区域
			 * @Author: Niklaus
			 * @Date: 2021-02-26 14:37:54
			 * @param {*} dom
			 */
			toView(dom) {
				dom.target.scrollIntoView();
			},

			onInput(dom, item) {
				let tt = /^[1-9][0-9]*$/

				if (!tt.test(dom.value)) {
					dom.value = ''
					item.num = ''
				}
			}
		},
	}
</script>

<style scoped>
	#add-form>div {
		padding: 0.1rem 0.5rem;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		line-height: 1rem;
		position: relative;
	}

	#add-form>div>p {
		width: 2rem;
		font-weight: 800;
	}

	#add-form>div>i {
		pointer-events: none;
	}

	#add-form>div>span {
		display: inline-block;
		margin-left: 0.3rem;
		margin-right: 0.5rem;
	}

	#add-form>div:first-child>input {
		width: calc(100% - 4rem);
	}

	#add-form>div:not(:first-child)>input {
		width: calc(100% - 2.8rem);
	}

	.lh5 {
		position: absolute;
		right: 0.5rem;
		top: 0.1rem;
	}

	.greenLi {
		position: relative;
		display: inline-block;
		width: calc(100% - 2rem);
		background-color: transparent;
		border: none;
		color: #00CCB2;
		text-align: left;
		padding: 0;
		padding-right: 0.1rem;
	}

	.delBtn {
		height: 0.65rem;
		padding: 0 0.35rem;
		line-height: 0.65rem;
		background-color: #FF7A7A;
		color: white;
		border-radius: 0.4rem;
	}

	.addBtn {
		height: 0.65rem;
		padding: 0 0.35rem;
		line-height: 0.65rem;
		background-color: #00CCB2;
		color: white;
		border-radius: 0.4rem;
		margin-left: 0.3rem;
	}

	.del {
		position: absolute;
		top: 0.1rem;
		right: 0.1rem;
		width: 0.45rem;
		height: 0.45rem;
		background-color: #EDEDED;
		content: '-';
		border-radius: 50%;
	}

	.del::before {
		width: 50%;
		height: 1px;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
		background-color: #A6A6A6;
	}

	.del::after {
		width: 1px;
		height: 50%;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
		background-color: #A6A6A6;
	}

	.greenLi:not(:first-child) {
		margin-top: 0.2rem;
	}

	.fc {
		font-style: normal;
		display: inline-block;
		width: 1.3rem;
		background-color: #00CCB2;
		color: #fff;
		padding: 0.1rem 0.1rem;
		font-size: 0.3rem;
		line-height: 0.5rem;
		margin-left: 0.2rem;
		border-radius: 4px;
	}

	.step {
		width: 2rem;
		border-radius: 1rem;
		border: 1px solid #00CCB2;
		text-align: center;
	}

	.step::placeholder {
		font-size: 0.36rem;
	}

	.step:focus::placeholder {
		color: transparent;
	}
</style>
